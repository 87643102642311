module.exports = [{
      plugin: require('/Users/adam/sandbox/my-blog-starter/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/adam/sandbox/my-blog-starter/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/adam/sandbox/my-blog-starter/node_modules/gatsby-plugin-typography/gatsby-browser'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/adam/sandbox/my-blog-starter/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-131689316-1","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"oneismine.com"},
    },{
      plugin: require('/Users/adam/sandbox/my-blog-starter/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
